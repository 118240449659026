@import 'styles/_colors.scss';

.billing-usage-spending-limit-container {
  display: flex;
  flex-direction: column;
  gap: 14px;
  border-color: rgb(224, 224, 224);
  border-width: thin;
  border-radius: 5px;
  border-style: solid;
  margin-bottom: 50px;
  height: auto;
  padding: 24px 24px 28px 21px;
  background-color: $white;

  .billing-usage-spending-limit-header {
    grid-column: 1 / 3;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .billing-usage-spending-limit-header-text {
    margin: 0px;
    font-size: 15px;
    font-weight: 600;

    .actions-by-app-tooltip .icon,
    .actions-over-time-tooltip .icon {
      position: relative;
      top: 3px;
      left: 10px;
      height: 16px;
      width: 16px;
      mask-size: 16px;
    }
  }

  .billing-usage-spending-limit-content {
    .billing-usage-spending-limit-select.wrapped-select {
      width: 260px;
    }

    &-unlimited {
      p {
        color: $label;
        font-size: 12px;
        line-height: 13px;
      }
    }

    &-custom {
      display: flex;
      flex-direction: column;
      gap: 18px;

      &-stats {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        margin-top: 15px;
      }

      &-header {
        display: flex;
        flex-direction: column;
        gap: 4px;
        margin-top: 10px;

        span {
          color: $body;
          font-size: 13px;
          line-height: 15px;
        }

        p {
          font-size: 12px;
          line-height: 12px;
          color: $labelDark;
        }
      }

      &-content {
        display: flex;
        flex-direction: column;
        gap: 12px;
        grid-column: 2;
        justify-content: flex-end;

        .multi-menu-trigger {
          max-height: 500px;
          width: 50%;
        }

        p {
          font-size: 12px;
          line-height: 12px;
          color: $label;
        }

        &-editing {
          display: flex;
          align-items: center;
          align-self: flex-end;
          gap: 4px;
          margin-top: 10px;
        }

        &-buttons {
          display: flex;
          align-items: center;
          justify-content: flex-end;

          &.with-banner {
            justify-content: space-between;
          }

          .billing-usage-banner {
            display: flex;
            align-items: center;
            align-self: flex-start;
            padding: 4px 8px;
            border-radius: 4px;

            &.warning {
              border: 1px solid rgba(239, 144, 0, 0.2);
              background: rgba(239, 144, 0, 0.2);
            }

            &.error {
              border: 1px solid rgba(239, 76, 48, 0.2);
              background: rgba(239, 76, 48, 0.2);
            }

            p {
              display: flex;
              align-items: center;
              gap: 0.5rem;
            }
          }

          p {
            font-size: 12px;
            font-weight: 500;
            color: $labelDark;

            strong {
              color: $body;
            }
          }

          span {
            font-size: 11px;
            font-weight: 600;
            text-transform: uppercase;
            color: #00a898;
            text-transform: uppercase;
            font-weight: 600;
            padding: 0.2em;
            cursor: pointer;

            &:hover {
              background: rgba(0, 168, 152, 0.2);
            }
          }
        }
      }
    }
  }
}

.billing-usage-spending-limit-toggle {
  label {
    color: #727272;
  }
}

.billing-usage-spending-limit {
  grid-column: 1 / 3;
  grid-row: 2 / 3;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 14px 0 19px;
}

.billing-usage-spending-limit-tooltip {
  display: grid;
  flex-direction: column;
  background: #fcfcfc;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid rgb(224, 224, 224);
  grid-template-columns: 1.5fr 0.5fr;
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.15),
    0px 3px 4px 0px rgba(0, 0, 0, 0.12), 0px 1px 8px 0px rgba(0, 0, 0, 0.11);
}

.billing-usage-spending-limit-tooltip h5 {
  margin: 0px 0px 0px 5px;
}

.billing-usage-spending-limit-tooltip-label {
  display: flex;
  grid-column: 1 / 2;
  align-items: center;
  padding: 8px 0px;
}

.billing-usage-spending-limit-tooltip-value {
  display: flex;
  grid-column: 2 / 3;
  align-items: center;
  text-align: center;
  justify-content: flex-start;
  padding: 8px 8px;
}

.billing-usage-spending-limit-tooltip hr {
  border: none;
  border-top: 1px solid rgb(224, 224, 224);
  margin: 0px -10px;
  grid-column: 1/3;
}
