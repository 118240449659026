// BRANDING
$darkPink: #a82058;
$orange: #ef4c30;
$yellow: #ffc00e;
$green: #a1cd46;
$teal: #00a898;
$purple: #43437a;
$red: #e0432f;
$pink: #fdeeed;

// LIGHT BRANDING
$lightDarkPink: rgba($darkPink, 0.1);
$lightOrange: rgba($orange, 0.1);
$lightYellow: rgba($yellow, 0.1);
$lightGreen: rgba($green, 0.1);
$lightTeal: rgba($teal, 0.1);
$lightPurple: rgba($purple, 0.1);
$lightRed: rgba($red, 0.1);
$lightPink: rgba($pink, 0.1);

// MEDIUM BRANDING
$mediumTeal: rgba($teal, 0.15);
$mediumData: rgba($orange, 0.2);

// DARK BRANDING
$darkTeal: #007b69;

// GENERAL
$grey: #eee;
$gray: $grey;
$darkGrey: #e0e0e0;
$darkGray: $darkGrey;
$midGrey: #dedede;
$midGray: $midGrey;
$darkerGrey: #aaa;
$darkerGray: $darkerGrey;
$lightGrey: #f5f5f5;
$lightGray: $lightGrey;
$black: #333333;
$white: #ffffff;
$backgroundGray: #f1f1f1;
$backgroundGrey: #f1f1f1;

// TYPOGRAPHY
$body: #000000;
$body2: #757575;

$label: #8a8a8a;
$labelDark: #424242;
$subtitle: #bdbdbd;
$filterLabel: #616161;
$placeholder: #9e9e9e;

// ALIASES
// --- sections ---
// --- DATA ---
$data: $orange;
$lightData: $lightOrange;

// --- BRANDING ---
$branding: $darkPink;
$lightBranding: $lightDarkPink;

// --- SCREENS ---
$screens: $teal;
$lightScreens: $lightTeal;

// --- SETTINGS ---
$settings: $purple;
$lightSettings: $lightPurple;

// --- PUBLISH ---
$publish: $green;
$lightPublish: $lightGreen;

// --- properties ---
$border: $grey;
$borderDark: #bdbdbd;
