.choose-pricing-plan-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 80vh;
  text-align: center;
}

h1.page-not-found {
  margin-bottom: 10px;
  font-size: 64px;
  font-weight: 600;
}

h1.page-not-found + p {
  color: #333333;
  margin-bottom: 30px !important;
  font-size: 28px;
}

p.page-not-found {
  color: #8a8a8a;
  margin-bottom: 30px !important;
  font-size: 16px;
}
