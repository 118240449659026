.legend-icon {
  display: inline-block;
  height: 16px;
  width: 16px;
  border: 1px solid;
  border-radius: 2px;

  &-circle {
    border-radius: 50%;
  }
}

.legend-item {
  display: inline-flex;
  align-items: center;
  margin-right: 15px;
}

.legend-label {
  margin-left: 5px;
  font-weight: 500;
  font-size: 9px;
}
