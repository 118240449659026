.billing-usage-chart-container {
  display: grid;
  border-color: rgb(224, 224, 224);
  border-width: thin;
  border-radius: 5px;
  border-style: solid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 75px 1fr;
  margin-bottom: 50px;
  height: auto;
}

.billing-usage-chart-header {
  grid-column: 1 / 3;
  grid-row: 1 / 2;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 19px;
}

.billing-usage-chart-header-text {
  margin: 0px;
  font-size: 15px;
  font-weight: 600;

  .actions-by-app-tooltip .icon,
  .actions-over-time-tooltip .icon {
    position: relative;
    top: 3px;
    left: 10px;
    height: 16px;
    width: 16px;
    mask-size: 16px;
  }
}

.billing-usage-chart-bill-period {
  background-color: #ededed;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  padding: 15px;
}

.billing-usage-chart-bill-period p {
  margin: 0px 0px 0px 10px;
  font-size: 10px;
}

.billing-usage-chart {
  grid-column: 1 / 3;
  grid-row: 2 / 3;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 14px 0 19px;
}

.billing-usage-chart-legend {
  border-top: 1px solid rgb(224, 224, 224);
  grid-column: 1 / 3;
  grid-row: 3 / 4;
  display: flex;
  align-items: center;
  height: 40px;
  padding: 10px 0px 10px 35px;
}

.billing-usage-chart-tooltip {
  display: grid;
  flex-direction: column;
  background: #fcfcfc;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid rgb(224, 224, 224);
  grid-template-columns: 1.5fr 0.5fr;
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.15),
    0px 3px 4px 0px rgba(0, 0, 0, 0.12), 0px 1px 8px 0px rgba(0, 0, 0, 0.11);
}

.billing-usage-chart-tooltip h5 {
  margin: 0px 0px 0px 5px;
}

.billing-usage-chart-tooltip-label {
  display: flex;
  grid-column: 1 / 2;
  align-items: center;
  padding: 8px 0px;
}

.billing-usage-chart-tooltip-value {
  display: flex;
  grid-column: 2 / 3;
  align-items: center;
  text-align: center;
  justify-content: flex-start;
  padding: 8px 8px;
}

.billing-usage-chart-tooltip hr {
  border: none;
  border-top: 1px solid rgb(224, 224, 224);
  margin: 0px -10px;
  grid-column: 1/3;
}

.app-actions-by-app-chart-container {
  display: grid;
  border-color: rgb(224, 224, 224);
  border-width: thin;
  border-radius: 5px;
  border-style: solid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto auto auto 46px 34px;
  margin-bottom: 2em;
}

.app-actions-by-app-chart {
  grid-column: 1 / 3;
  grid-row: 4/ 5;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 14px;
}

.app-actions-by-app-legend-wrapper {
  grid-column: 1/3;
  grid-row: 2/3;
  padding: 0 20px;
}

.app-action-by-app-bar-label-wrapper {
  display: flex;
  width: 100%;
  justify-content: space-between;
  grid-row: 5/-1;
  grid-column: 1/-1;
  padding: 0 19px 24px;
  line-height: 14px;
}

.app-actions-by-app-legend {
  list-style-position: inside;
  padding: 0 0 0 20px;
}

.app-actions-by-app-chart-legend {
  grid-column: 1 / 3;
  grid-row: 2 / 3;
  display: flex;
  align-items: center;
  padding: 0 19px;
}

.days-left-in-cycle-label-wrapper {
  display: flex;
  width: 100%;
  justify-content: right;
  grid-row: 3 / 4;
  grid-column: 1/-1;
  padding: 0 19px;
}

.days-left-in-cycle-label {
  margin-bottom: 2px;
  margin-top: 18px;
  color: '#8A8A8A';
  height: 14px;
  font-size: 12px;
  font-family: 'Work Sans', sans-serif;
  font-weight: 500;
}

.app-name-label {
  margin: 0 3px 0 10px;
  font-size: 15px;
  line-height: 17px;
  font-family: 'Work Sans', sans-serif;
}

.app-actions-label {
  margin: 0 3px;
  font-size: 12px;
  line-height: 14px;
  font-family: 'Work Sans', sans-serif;
  color: #626262;
}

.published-icon {
  margin: 0 6px;
}

.app-action-by-app-legend-ul {
  list-style-type: none;
}

.app-action-by-app-legend-li {
  margin: 5px 0;
}

.app-action-by-app-legend-ul .app-action-by-app-legend-li:first-child {
  margin-top: 0;
}

.app-action-by-app-legend-ul .app-action-by-app-legend-li:last-child {
  margin-bottom: 0;
}

.legend-item {
  height: 22px;
}

.action-count-label {
  font-size: 12px;
  font-weight: 600;
  color: '#424242';
  font-family: 'Work Sans', sans-serif;
}

.action-count-text {
  font-size: 12px;
  font-weight: 500;
  color: '#424242';
  font-family: 'Work Sans', sans-serif;
}

.current-cycle-tooltip-text-container {
  width: 155px;
}

.current-cycle-tooltip-text {
  font-size: 10px;
  line-height: 12px;
}

.current-cycle-tooltip-container {
  margin-bottom: 6px;
}

.calendar-icon,
.icon-calendar-blank {
  background: #727272;
}

.empty-state-header {
  font-size: 12px;
  font-weight: 500;
  margin-bottom: 10px;
  line-height: 14px;
  color: #616161;
}

.empty-state-message {
  font-size: 12px;
  font-weight: 500;
  line-height: 14px;
  color: #9e9e9e;
}

.app-actions-over-time-empty-state {
  margin-bottom: 34px;
}
