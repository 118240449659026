@import 'styles/_colors.scss';

$tablet-breakpoint: 1139px;
$mobile-breakpoint: 719px;
$vertical-scroll-breakpoint: 790px;

.auth-screen {
  background: #fff url('./bg@2x.png') no-repeat center;
  background-size: cover;
  background-attachment: fixed;
  color: #333;
}

.auth-page {
  max-width: 460px;
  padding: 10px 30px;
  min-height: 100%;
  margin: 0 auto;
  text-align: center;
  display: flex;
  flex-direction: column;
  transition: padding-top 0.2s;
}

.auth-page-v2 {
  width: 100%;
  min-height: 100%;
  margin: 0 auto;
  text-align: center;
  display: flex;
  flex-direction: column;
  transition: padding-top 0.2s;

  h1 {
    color: $black;
    text-align: left;
    font-size: 32px;
    font-weight: 600;
    margin-top: 64px;
    margin-bottom: 34px;
  }
}

.auth-page-has-error {
  padding-top: 60px;
}

.auth-page-error {
  line-height: 20px;
  padding: 10px 0;
  background: #fc3;
  color: #000;
  font-weight: 600;
  font-size: 14px;
  position: fixed;
  top: -40px;
  left: 0;
  right: 0;
  transition: top 0.2s;
}

.auth-page-has-error .auth-page-error {
  top: 0;
}

.auth-page-body {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
  padding: 30px 0;
}

.auth-page-body-v2 {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
}

.auth-page h1 {
  font-size: 24px;
  font-weight: 400;
  margin-bottom: 50px;
}

.auth-page h1 + p {
  margin-top: -36px;
  margin-bottom: 40px;
  color: #8a8a8a;
}

.auth-page-logo {
  position: absolute;
  top: -69px;
  left: 0;
  right: 0;
  height: 120px;
  background: url('./f-logo@2x.png') no-repeat center;
  background-size: contain;
}

.auth-form {
  font-size: 14px;
  padding: 40px;
  padding-top: 60px;
  margin-top: 60px;
  background: #fff;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 5px -1px,
    rgba(0, 0, 0, 0.14) 0px 6px 10px 0px, rgba(0, 0, 0, 0.12) 0px 1px 18px 0px;
  border-radius: 10px;
  position: relative;
}

.auth-form-v2 {
  flex: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  font-size: 14px;
  padding: 60px;
  gap: 34px;
  overflow-y: auto;

  &__header {
    display: flex;
    flex-direction: column;
    gap: 65px;

    h1 {
      margin: 0;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: 10px;

    label {
      margin-left: 0;
      font-size: 12px;
    }

    .wrapped-input-error-message {
      font-size: 12px;
    }

    .auth-form-submission-row {
      margin: 0;
    }
  }

  &__footer {
    display: flex;
    flex-direction: column;
    gap: 17.5px;
    height: 100%;

    div.terms-of-use {
      margin-top: 0;
    }

    div.auth-form-link-v2 {
      margin-top: auto;
      margin-bottom: 0;
    }
  }

  .terms-of-use {
    margin-top: 24px;
    color: $body2;
    text-align: center;
    font-family: Work Sans;
    font-size: 14px;
    font-weight: 400;

    a {
      color: $body2;
      font-weight: 700;
    }
  }

  .auth-form-link,
  .auth-form-link-v2 {
    &:hover {
      opacity: 0.7;
    }
  }

  .auth-form-link-v2 {
    margin-top: auto;
    margin-bottom: 64px;
    &:hover {
      text-decoration: none;
    }
  }

  .link-button {
    border-radius: 4px;
    background: rgba(0, 168, 152, 0.1);
    padding: 0 4px;
  }

  .form-row {
    position: relative;
    margin: 0;
    width: 100%;

    min-height: 96px;

    .form-row-inner-layout {
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;

      .wrapped-input {
        width: 100%;
        margin: 0;
      }
    }
  }

  @media screen and (max-width: $tablet-breakpoint) {
    max-width: 620px;
    margin: auto;
  }

  @media screen and (max-width: $mobile-breakpoint) {
    padding: 82px 16px 0px;

    @media screen and (max-height: 800px) {
      padding: 32px 16px 0px;

      &__header {
        gap: 24px;
      }
      &__content {
        .form-row {
          min-height: 82px;
        }
      }
    }

    @media screen and (max-height: $vertical-scroll-breakpoint) {
      padding-bottom: 120px;
    }

    h1 {
      text-align: center;
      margin-top: 16px;
      font-size: 32px;
      font-weight: 600;
      background: linear-gradient(180deg, #00a898 0%, #006b5a 100%);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    p {
      text-align: center;
    }

    .auth-form-submission-row {
      .btn {
        height: 66px;

        @media screen and (max-height: 800px) {
          height: 46px;
        }
      }
    }

    .auth-form-link-v2 {
      position: fixed;
      bottom: 0;
      left: 0;
      right: 0;
      padding: 18px 16px;
      background: rgb(230, 246, 245);
      color: $black;
      margin-bottom: 0;
      display: flex;
      justify-content: center;
      align-items: center;

      .link-button {
        background: transparent;
      }
    }

    .form-row input {
      font-size: 16px;
    }
  }
}

.auth-form .btn {
  width: 100%;
  display: block;
  margin-top: 20px;
}

.auth-form .flat-button {
  width: 100%;
  display: block;
  margin-top: 20px;
  background-color: #ffbf0e00;
  color: #333333;
}

.auth-form-error {
  color: #f44;
  margin: 15px 0;
}

.auth-form-submission-row {
  margin-top: 40px;
}

.auth-form-link {
  margin-top: 30px;
}

.auth-form-link a {
  color: inherit;
}

.auth-page .wrapped-input input {
  font-size: 16px;
}

.auth-page .wrapped-input label {
  margin: 12px 16px 8px;
}

.auth-page .wrapped-input .wrapped-input-inner-wrapper {
  padding: 7px 15px;
  border: 1px solid #e0e0e0;
  border-radius: 9px;
}

.auth-page .wrapped-input-focussed .wrapped-input-inner-wrapper,
.auth-page .wrapped-input-error .wrapped-input-inner-wrapper {
  border-color: #ccc;
}

.auth-page .wrapped-input-error-message {
  color: #fff;
  font-weight: normal;
  background: #333;
  margin-top: 6px;
  padding: 10px 15px;
  border-radius: 10px;
  position: relative;
}

.auth-page .wrapped-input-error-message:before {
  content: '';
  display: block;
  position: absolute;
  left: 50%;
  bottom: 100%;
  margin-left: -6px;
  border: 6px solid transparent;
  border-bottom-color: #333;
  pointer-events: none;
}

@media (min-width: 791px) {
  .auth-page .wrapped-input-error-message {
    position: absolute;
    left: 100%;
    bottom: 16px;
    margin: 0 0 0 10px;
    white-space: nowrap;
  }

  .auth-page .wrapped-input-error-message:before {
    left: auto;
    right: 100%;
    bottom: auto;
    top: 50%;
    margin: -6px 0 0 0;
    border-color: transparent;
    border-right-color: #333;
  }

  .auth-page .checkbox-row .wrapped-input-error-message {
    top: 50%;
    margin-top: -18px;
    bottom: unset;
  }
}
