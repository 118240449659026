@import 'styles/_colors.scss';
@import 'styles/_form.scss';

.auth-page.user-questions-page {
  max-width: 506px;

  .auth-form {
    @include form-style;
  }
}

.user-questions-form {
  @include form-style;
}
